import { countdown } from './components/countdown'
import { input } from './components/input'
import { select } from './components/select'
import { video } from './components/video'
import { drawer } from './components/drawer'
import { accordion } from './components/accordion'
import { newsletter } from './components/forms/newsletter'
import { recentPosts } from './components/recentPosts'

countdown()
input()
select()
video()
drawer()
accordion()
newsletter()
recentPosts()
